import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }

      &.txt-color-red {
        color: #ff6969;
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 50px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }

  ${BreakPoints.large} {
  }
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0075 = (props: Props): JSX.Element => (
  <Wrapper>
    <ColumnH1 label="週休3日制についてメリットやデメリット、導入するにあたってのポイントとは？" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2024.09.26</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        事業運営していくにあたっては、労働者からの労務提供が必要となり、継続的な事業運営を進めていくにあたっては一定以上の休日が必要となります。もちろん多くの企業では繁忙期と閑散期があり、繁忙期においては休日労働の必要性も高まることでしょう。しかし、近年はワークライフバランスの機運が高まっており、その中の一つに「週休3日制」があります。今回は「週休3日制」について、注目される理由からメリットデメリットについても解説します。
      </p>
      <img src="/images/column/details/c0075.jpg" alt="キンクラコラム画像75" />
      <div id="toc1">
        <h1>法律上の休日と似て非なる休暇とは</h1>
      </div>
      <p>
        労働基準法では、少なくとも週に1日、あるいは4週間を通じて4日以上の休日の確保が求められています。後者は変形休日制と呼ばれており、採用するにあたっては労働者が認識できるように就業規則等へ明示することが求められています。「休日」と似て非なるものとして「休暇」が挙げられます。まず、
        <span>「休日」とは、労働提供の義務がない日</span>
        となりますので、一般的な会社員の場合は月曜日から金曜日を労働日として、土曜日と日曜日を休日とするケースが多いでしょう。また、休日には法定休日と法定外休日があり、法定休日は「特定することが望ましい」とされています。
        <br />
        <br />
        次に
        <span>
          「休暇」については、労働義務があるものの、労働者が権利を行使することによって、労働義務が消滅した日
        </span>
        を指します。イメージしやすい休暇は年次有給休暇が挙げられます。尚、年次有給休暇は唯一、法律によって賃金支払い義務が課せられている休暇となります。
      </p>

      <div id="toc2">
        <h1>週休3日制が注目される理由</h1>
      </div>
      <p>
        近年、ワークライフバランスの機運が高まっており、仕事一辺倒や業務終了後も職場での付き合いを重視する考え方から、一定の変化が見られています。もちろん、企業として一定の利益を上げていかなければ企業の存続も危ぶまれることとなるため、成果を上げずに休日だけを増加する動きは本末転倒と言わざるを得ません。ただし、近年は雇用の流動化が促進されており、新卒採用だけでなく、有能な中途採用者の加入によって、より生産性の高い労務提供が行われていることや、健康意識の高まりを受けて、週休3日制が注目されています。
      </p>

      {/** ここに9パターンの広告設定する */}
      {props.no === 0 && <Introduction004 />}
      {props.no === 1 && <Introduction005 />}
      {props.no === 2 && <Introduction006 />}
      {props.no === 3 && <Introduction007 />}
      {props.no === 4 && <Introduction008 />}
      {props.no === 5 && <Introduction009 />}
      {props.no === 6 && <Introduction010 />}
      {props.no === 7 && <Introduction011 />}
      {props.no === 8 && <Introduction012 />}

      <div id="toc3">
        <h1>週休3日制のメリット</h1>
      </div>
      <p>
        単純に休日が増えることで、育児や介護と並行して労務提供している労働者の場合は、その負担が軽減し、より長期的な労務提供が可能となり得ることです。また、そのような
        <span>雇用環境が対外的にも広がることでよりよい人財確保にも繋がる</span>
        と考えられます。「休日」となれば、そもそも仕事をしに出勤することすらなくなるため、出勤した場合に副次的に発生し得る「お付き合い残業」もなくなることから、人件費を抑えられるというメリットもあります。
      </p>

      <div id="toc4">
        <h1>週休3日制のデメリット</h1>
      </div>
      <p>
        週休3日制は我が国ではまだ広まっているとは言えず、取引先の多くが週休2日制と思われ、
        <span>コミュニケーション不足による損失</span>
        が生まれる場合があります。偏に損失と言っても、不良品や、オーダーとは異なる商品を納入してしまうことによるクライアント側が被る損失や、クライアントに眠っているニーズを把握できない（コミュニケーション不足が原因）ことによって提案機会を逸したことによる自社側の損失も想定されます。
        <br />
        <br />
        しかし、これらの損失は、週休2日にすることで回避できるという問題ではありませんので、週休3日制に全面的に原因があるとは言えず、
        <span>根本的な原因は他の部分</span>にあると言えます。
      </p>

      <div id="toc5">
        <h1>週休3日制を導入するにあたってポイントとは</h1>
      </div>
      <p>
        まずは、<span>社内での事前周知と納得感</span>
        です。週休3日制に限らず、新たな制度の導入にあたっては事前の周知は不可避となります。ただし、トップダウンで周知したところで、現場の労働者からの真の意味での納得感を得ることは難しいと考えます。週休3日制を導入することで一日単位の時間外労働時間数が増え、結果的に健康被害が出てしまうようでは導入するメリットがあるとは思えません。
        <br />
        <br />
        次にクライアントへの説明です。もちろん、クライアントも同じく週休3日制の導入を検討しているのであれば納得感は得られやすいのでしょうが、そのようなケースは多くなく、単に連絡がつきづらくなると思われ、無駄に印象を悪くする結果だけは回避すべきです。なぜ、
        <span>
          週休3日を導入するのか必要性を説明し、週休3日制を導入したことによるデメリットの緩和策を示す
        </span>
        などの姿勢が重要です。
      </p>

      <div id="toc6">
        <h1>最後に</h1>
      </div>
      <p>
        「長時間労働」は心身を蝕み、過去には人命に関わる社会的な問題にも発展しています。また、長時間労働をしている本人には自覚がないことも珍しくなく、会社の制度として週休3日制を導入することで、いくばくかは長時間労働の抑制に寄与すると考えられます。他方、週休3日制を導入することで1日単位の負担がどれほど大きくなるのか、どのような部署にどの程度のしわよせがいくのかを十分検証し、導入することが重要です。また、一度導入したとしても、自社にとってよりよい制度となるようにカスタマイズしていくことも有用です。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0075
